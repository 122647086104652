import CheckWebpFeature from "./src/Components/common/ggfx-client/module/webp-checker";
CheckWebpFeature();

export { wrapRootElement } from "./src/apollo/wrap-root-element";
const addScript = url => {
  const script = document.createElement("script")
  script.src = url
  script.async = true
  document.body.appendChild(script)
}

export const onClientEntry = () => {
  window.onload = () => {
    addScript("https://clients.yomdel.com/tools/chat_script.js?url=" + window.location.href)    
  }
}

export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition
}) => {
  
    // Avoid to scroll to top when next page trigger click
    // But this feature should condiser with details page back scroll too
    if (location.pathname.match(/properties-for-sale/)) {
      return false;
    }
    if (location.pathname.match(/properties-to-rent/)) {
      return false;
    }
    if (location.pathname.match(/properties-map\/for-sale/)) {
      return false;
    }

    if (location.pathname.match(/properties-map\/to-rent/)) {
      return false;
    }
  
    return true;
};